import React, { PureComponent } from 'react'

import { SettingsContext } from '../../settings-context'

const Option = ({ 
  type, 
  value
  }) => {

  return (
    <SettingsContext.Consumer>
        {(context) => {
          const selectThisSetting = () => {
            context.changeSetting(type, value)
          }
          return (
            <div
              onClick={selectThisSetting}
              className={`tac c6 bcb b1 ttu curp option ${context[type] == value ? 'active' : 'unactive'}`}>{value}</div>
          )
        }}

    </SettingsContext.Consumer>
  )
}

const SettingsBar = ({
  toggleSettings, 
  active = false
  }) => {
  return (
    <aside id='settings' className={`psf t0 bcb b1 px1 py1 z4 bgc-g t-t x xw xjb ${active && 'active'}`}>
      <section className='x xw mb1 option-row'>
        <Option
          type='color' 
          value='light' />
        <Option
          type='color' 
          value='dark' />
        <Option
          type='color'
          value='red' />
        <Option
          type='color'
          value='nge' />
      </section>
      <section className='x xw mb1 option-row'>
        <Option
          type='font'
          value='sans' />
        <Option
          type='font'
          value='serif' />
        <Option
          type='font'
          value='mono' />
        <Option
          type='font'
          value='slab' />
      </section>
      <section className='curp' onClick={toggleSettings}>
        Close
      </section>
    </aside>
  )
}

class Settings extends PureComponent {
  constructor () {
    super()
    this.state = {
      color: 'light',
      font: 'sans'
    }

    this.toggleSettings = this.toggleSettings.bind(this)
    this.setActiveSetting = this.setActiveSetting.bind(this)
  }

  setActiveSetting (setting, value) {
    if (setting === 'color') {
      this.setState({ color: value })
    } else {
      this.setState({ font: value})
    }
  }

  toggleSettings () {
    // this.setState({ active: !this.state.active})
    this.props.toggleActiveState('settingsActive', !this.props.active)
  }

  render () {
    const { active } = this.props
    return (
      <>
        <div onClick={this.toggleSettings} className='bcb br1 bb1 c3 px1 py1 curp opener'>
          <span>Settings</span>
        </div>
        <SettingsBar
          activeColor={this.state.color}
          activeFont={this.state.font}
          toggleSettings={this.toggleSettings}
          setActiveSetting={this.setActiveSetting}
          active={active}
        />
      </>
    )
  }
}

export default Settings
