import React from 'react'

const ErrorFields = ({ errorFields }) => {
  return (
    <section className='mt2'>
      {/* {errorFields && <span>Please fill in fields marked in red</span>} */}
    </section>
  )
}

export default ErrorFields
