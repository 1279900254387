import React, { PureComponent } from 'react'
import { createForm, formShape } from 'rc-form'

import ErrorFields from './ErrorFields'

const Input = ({ 
  errorFields,
  getFieldProps,
  placeholder,
  name,
  optional = false,
  css = 'c12' }) => {
    // light error handling to show Red if empty
    let errorField = false
    let processedName = name.replace('{', '[').replace('}', ']')

    if (errorFields[name] && errorFields[name][0]) {
      errorField = errorFields[name][0]
    }
    
    return (
      <div className={`${css} input-field ${errorField ? 'error' : 'clean'}`}>
        <input name={processedName} className='c12 bcb b1' placeholder={placeholder} {...getFieldProps(name, {
          rules: [{ 
            required: !optional,
            type: name === 'MERGE0' ? 'email' : 'string'
          }]
        })} />
      </div>
    )
  }

class Form extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      submitted: false
    }
  }
  
  submit = (e) => {
    let errors = false
    e.preventDefault()
    this.props.form.validateFields((error, value) => {
      if (error) errors = true
    })

    if (errors) {
      e.preventDefault()
    } else {
      // e.target.submit()
    }
  }

  convertObjectToArr = (obj) => {
    const arr = obj.map(item => item.name)
    return arr
  }
  
  render () {
    let errors
    const { getFieldProps, getFieldsError } = this.props.form
    const { newsletterStructure, submitted } = this.props
    
    const errorFields = getFieldsError(this.convertObjectToArr(this.props.newsletterStructure))

    return (
      <>
        <form method='POST' action='https://digital.us20.list-manage.com/subscribe/post' onSubmit={this.submit} sm='c12' className={`form-submitted-${submitted} c5 x xw xjb`}>
          <input type='hidden' name='u' value='3d6d6a4c3380646197218a2d4' />
          <input type='hidden' name='id' value='b8ec0e406c' />
        
          {newsletterStructure.map(input => <Input 
            errorFields={errorFields}
            key={`${input.placeholder}`}
            getFieldProps={getFieldProps}
            {...input} />)}

          <button className='form-submit bg-w c12 curp c-b'>Place Order</button>
          <ErrorFields errorFields={errorFields} />
        </form>
      </>
    )
  }
}

export default createForm()(Form)
