import React from 'react'
import { withPrefix } from 'gatsby'

import Newsletter from './Newsletter/'

const Footer = () =>
  <footer className='mxa pb4 pt4 tac x xw xjb xac fsC'>
    <Newsletter />
    <div sm='c10' className='br50 mw55 b1 bcb footer-dl mxa c6 mb4'>
      <a className='db fsB hyphens-off' href={withPrefix('on-practices-round-one-2019.pdf')} target='_blank' rel='noopener noreferrer'>Download On Practices Round One PDF</a>
    </div>
  </footer>

export default Footer
