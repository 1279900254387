import React, { PureComponent } from 'react'
import { withPrefix } from 'gatsby'

import Form from './Form'
import Submitted from './Submitted'

export const newsletterStructure = [
  {
    name: 'MERGE0',
    placeholder: 'Email Address *'
  },
  {
    name: 'MERGE1',
    placeholder: 'Name'
  },
  {
    name: 'MERGE3{addr1}',
    placeholder: 'Shipping Address'
  },
  {
    name: 'MERGE3{addr2}',
    placeholder: 'Address 2',
    optional: true
  },
  {
    name: 'MERGE3{city}',
    placeholder: 'City'
  },
  {
    name: 'MERGE3{state}',
    placeholder: 'Province / State',
    css: 'c6'
  },
  {
    name: 'MERGE3{zip}',
    placeholder: 'Postal Code',
    css: 'c6'
  },
  {
    name: 'MERGE3{country}',
    placeholder: 'Country'
  },
  {
    name: 'MERGE4',
    placeholder: 'Phone Number',
    optional: true
  }
]

class Newsletter extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      available: true,
      submitted: false
    }
  }

  componentDidMount () {
    const submitted = window.location.search === '?submitted=true'
    if (window.sessionStorage.getItem('SUBMITTED') === 'true') {
      this.setState({ submitted: true })
    }

    if (submitted) {
      this.setState({ submitted }, () => {
        window.sessionStorage.setItem('SUBMITTED', true)
        window.scrollTo(0, 9999)
      })
    }
  }

  render () {
    const { submitted } = this.state
    return (
      <>
        <section id='newsletter' className='px1 py4 mxa c12 psr'>
          <div className='psa c c12 all-gone z2'>
            <div>ALL GONE</div>
          </div>
        
          <div className='newsletter-child z1'>
            <div className={`submitted-${submitted}`}>
              <h1 className='form-title ttu lh1 c12 mb1'>Get the printed book for free</h1>
              <p className='fsC'>Limited run of 100 copies shipping February 25</p>

              <div className='x xw xac xjb py4 mw50 mxa psr'>
                <figure sm='c12 pb2' className='c7 px1 py1 psr transient'>
                  <img className='px1 py1 z2 psr' src={withPrefix('cover.jpg')} />
                </figure>

                <Form newsletterStructure={newsletterStructure} />
              </div>

              <p>* We will send you <span className='tdu'>two</span> emails: one to confirm shipping and another announcement in the Spring</p>
            </div>
            {submitted && <Submitted />}
          </div>
        </section>
      </>
    )
  }
}

export default Newsletter
