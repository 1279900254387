import React from 'react'

const Submitted = () =>
  <section className='c12 tac py4 px1'>
    <h1 className='form-title ttu fwb'>
      Your order <br />
      has been placed <br /> <br />
      we will send <br />
      shipping confirmation <br />
      Feb 25 - Mar 1 <br /> <br />
      Keep an Eye Out <br />
    </h1>
  </section>

export default Submitted
