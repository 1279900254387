import React, { PureComponent } from 'react'

import Logo56 from '../Logo56'

const Sidebar = ({ active = false }) => {
  return (
    <aside id='information' className={`hyphens-off psf bgc-g z4 fsC px1 py1 bcb bl1 t-t selected r0 ${active && 'active'}`}>
      <p>On Practices is an online editorial platform for dialogue on building and maintaining creative practices. Each round is comprised of ten text-based interviews with notable practitioners in various spaces.</p>

      <p>This project is typeset in Neue Haas Grotesk, Junicode Regular, Courier New and Sentinel Book. </p>
      <p>This website is statically generated through Gatsbyjs, content modeling is setup through Prismic.io, and is mirrored on the dat p2p network.</p>

      <p><a href='https://onpractices.com'>https://onpractices.com</a> <br /> <a href='dat://onpractices.com'>dat://onpractices.com</a></p>
      <p>Concieved, designed and developed by</p>
      <section className='mt pb2'>
        <Logo56 />
      </section>
    </aside>
  )
}

class Information extends PureComponent {
  toggleActive = () => {
    this.props.toggleActiveState('informationActive', !this.props.active)
  }
  
  render () {
    const { active } = this.props
    return (
      <>
        <div onClick={this.toggleActive} className='bcb bb1 c3 px1 py1 curp opener'>
          <span>{active ? 'Close' : <span>Info<span sm='dn'>rmation</span></span> }</span>
        </div>
        <Sidebar active={active} />
      </>
    )
  }
}

export default Information
