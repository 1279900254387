import React, { PureComponent } from 'react'
import { Link } from 'gatsby'

import Settings from './Settings'
import Information from './Information'

class Header extends PureComponent {
  render () {
    const { settingsActive, informationActive, toggleActiveState } = this.props
    return (
      <header id='main-header' className='c12 psf t0 l0 z4 fsC x xw bgc-g'>
        <div className='bcb br1 bb1 c6 px1 py1'>
          <Link className='db' to='/'>On Practices</Link>
        </div>
        <Settings toggleActiveState={toggleActiveState} active={settingsActive} />
        <Information toggleActiveState={toggleActiveState} active={informationActive} />
      </header>
    )
  }
}

export default Header
